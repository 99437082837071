// AUTH
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
// USER IDLE
export const USER_IS_IDLE = 'USER_IS_IDLE';
export const USER_IS_NOT_IDLE = 'USER_IS_NOT_IDLE';
// USER UNIT CHANGE
export const USER_UNIT_CHANGE = 'USER_UNIT_CHANGE';
export const USER_LANGUAGE_CHANGE = 'USER_LANGUAGE_CHANGE';
// BACKEND INFO
export const FETCH_BACKEND_INFO_REQUEST = 'FETCH_BACKEND_INFO_REQUEST';
export const FETCH_BACKEND_INFO_SUCCESS = 'FETCH_BACKEND_INFO_SUCCESS';
export const FETCH_BACKEND_INFO_FAIL = 'FETCH_BACKEND_INFO_FAIL';
// AZURE B2C
export const REDIRECT_LOGIN = 'REDIRECT_LOGIN';
export const REDIRECT_LOGOUT = 'REDIRECT_LOGOUT';
// SEARCH
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const UPDATE_SEARCH_TEXTFIELD = 'UPDATE_SEARCH_TEXTFIELD';
export const UPDATE_SEARCH_IGNITION_ON = 'UPDATE_SEARCH_IGNITION_ON';
export const UPDATE_SEARCH_IGNITION_OFF = 'UPDATE_SEARCH_IGNITION_OFF';
export const UPDATE_SEARCH_MACHINE_STATE = 'UPDATE_SEARCH_MACHINE_STATE';
export const UPDATE_SEARCH_MACHINE_TAGS = 'UPDATE_SEARCH_MACHINE_TAGS';
export const SEARCH_MACHINES = 'SEARCH_MACHINES';
export const UPDATE_FILTERED_MACHINES = 'UPDATE_FILTERED_MACHINES';
export const MACHINES_FOUND = 'MACHINES_FOUND';
export const NO_MACHINES_FOUND = 'NO_MACHINES_FOUND';
export const RESET_SEARCH_FILTERS = 'RESET_SEARCH_FILTERS';
export const UPDATE_MAP_CENTER = 'UPDATE_MAP_CENTER';
export const RESET_MAP_CENTER = 'RESET_MAP_CENTER';
// ONE SPEZIFIC MACHINE
export const FETCH_MACHINE_BY_ID_REQUEST = 'FETCH_MACHINE_BY_ID_REQUEST';
export const FETCH_MACHINE_BY_ID_SUCCESS = 'FETCH_MACHINE_BY_ID_SUCCESS';
export const FETCH_MACHINE_BY_ID_FAIL = 'FETCH_MACHINE_BY_ID_FAIL';
// ALL_MACHINES
export const FETCH_ALL_MACHINES_REQUEST = 'FETCH_ALL_MACHINES_REQUEST';
export const FETCH_ALL_MACHINES_SUCCESS = 'FETCH_ALL_MACHINES_SUCCESS';
export const FETCH_ALL_MACHINES_FAIL = 'FETCH_ALL_MACHINES_FAIL';
// MACHINE STATE OPTIONS
export const FETCH_MACHINE_STATE_OPTIONS_REQUEST =
  'FETCH_MACHINE_STATE_OPTIONS_REQUEST';
export const FETCH_MACHINE_STATE_OPTIONS_SUCCESS =
  'FETCH_MACHINE_STATE_OPTIONS_SUCCESS';
export const FETCH_MACHINE_STATE_OPTIONS_FAIL =
  'FETCH_MACHINE_STATE_OPTIONS_FAIL';
export const FETCH_MACHINE_TAG_OPTIONS_REQUEST =
  'FETCH_MACHINE_TAG_OPTIONS_REQUEST';
export const FETCH_MACHINE_TAG_OPTIONS_SUCCESS =
  'FETCH_MACHINE_TAG_OPTIONS_SUCCESS';
export const FETCH_MACHINE_TAG_OPTIONS_FAIL = 'FETCH_MACHINE_TAG_OPTIONS_FAIL';
// MAP
export const GET_USER_POSITION_REQUEST = 'FETCH_USER_GEO_POSITION_REQUEST';
export const GET_USER_POSITION_SUCCESS = 'FETCH_USER_GEO_POSITION_SUCCESS';
export const GET_USER_POSITION_FAIL = 'FETCH_USER_GEO_POSITION_FAIL';
// ADDRESS
export const FETCH_MACHINE_ADDRESS_REQUEST = 'FETCH_MACHINE_ADDRESS_REQUEST';
export const FETCH_MACHINE_ADDRESS_SUCCESS = 'FETCH_MACHINE_ADDRESS_SUCCESS';
export const FETCH_MACHINE_ADDRESS_FAIL = 'FETCH_MACHINE_ADDRESS_FAIL';
// MACHINE DETAILS
export const FETCH_MACHINE_DETAILS_REQUEST = 'FETCH_MACHINE_DETAILS_REQUEST';
export const FETCH_MACHINE_DETAILS_SUCCESS = 'FETCH_MACHINE_DETAILS_SUCCESS';
export const FETCH_MACHINE_DETAILS_FAIL = 'FETCH_MACHINE_DETAILS_FAIL';
export const RESET_MACHINE_DETAILS = 'RESET_MACHINE_DETAILS';
// MACHINE DETAILS ADDRESS
export const FETCH_MACHINE_DETAILS_ADDRESS_REQUEST =
  'FETCH_MACHINE_DETAILS_ADDRESS_REQUEST';
export const FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS =
  'FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS';
export const FETCH_MACHINE_DETAILS_ADDRESS_FAIL =
  'FETCH_MACHINE_DETAILS_ADDRESS_FAIL';
// PDF
export const CREATE_PDF_REPORT = 'CREATE_PDF_REPORT';
export const CREATE_PDF_REPORT_SUCCESS = 'CREATE_PDF_REPORT_SUCCESS';
export const CREATE_PDF_REPORT_FAIL = 'CREATE_PDF_REPORT_FAIL';
// MACHINE STATE
export const UPDATE_MACHINE_STATE_REQUEST = 'UPDATE_MACHINE_STATE_REQUEST';
export const UPDATE_MACHINE_STATE_SUCCESS = 'UPDATE_MACHINE_STATE_SUCCESS';
export const UPDATE_MACHINE_STATE_FAIL = 'UPDATE_MACHINE_STATE_FAIL';
// MACHINE TAG
export const ADD_MACHINE_TAG_REQUEST = 'ADD_MACHINE_TAG_REQUEST';
export const ADD_MACHINE_TAG_SUCCESS = 'ADD_MACHINE_TAG_SUCCESS';
export const ADD_MACHINE_TAG_FAIL = 'ADD_MACHINE_TAG_FAIL';
export const DELETE_MACHINE_TAG_REQUEST = 'DELETE_MACHINE_TAG_REQUEST';
export const DELETE_MACHINE_TAG_SUCCESS = 'DELETE_MACHINE_TAG_SUCCESS';
export const DELETE_MACHINE_TAG_FAIL = 'DELETE_MACHINE_TAG_FAIL';
// MACHINE NOTE
export const EDIT_MACHINE_NOTE_REQUEST = 'EDIT_MACHINE_NOTE_REQUEST';
export const EDIT_MACHINE_NOTE_SUCCESS = 'EDIT_MACHINE_NOTE_SUCCESS';
export const EDIT_MACHINE_NOTE_FAIL = 'EDIT_MACHINE_NOTE_FAIL';
export const DELETE_MACHINE_NOTE_REQUEST = 'DELETE_MACHINE_NOTE_REQUEST';
export const DELETE_MACHINE_NOTE_SUCCESS = 'DELETE_MACHINE_NOTE_SUCCESS';
export const DELETE_MACHINE_NOTE_FAIL = 'DELETE_MACHINE_NOTE_FAIL';
// MACHINE PROJECT CONSTRUCTION SITE
export const EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST =
  'EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST';
export const EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS =
  'EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS';
export const EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL =
  'EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL';
export const DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST =
  'DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST';
export const DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS =
  'DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS';
export const DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL =
  'DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL';
// TELEMETRY
export const LAST_TELEMETRY_TIMESTAMP =
  'LAST_TELEMETRY_TIMESTAMP';
// GET TELEMETRY DATA
export const FETCH_MACHINE_TELEMETRY_REQUEST =
  'FETCH_MACHINE_TELEMETRY_REQUEST';
export const FETCH_MACHINE_TELEMETRY_SUCCESS =
  'FETCH_MACHINE_TELEMETRY_SUCCESS';
export const FETCH_MACHINE_TELEMETRY_FAIL = 'FETCH_MACHINE_TELEMETRY_FAIL';
export const RESET_MACHINE_TELEMETRY = 'RESET_MACHINE_TELEMETRY';
// TELEMETRY DATA CONTROLS
export const UPDATE_START_DATE = 'UPDATE_START_DATE';
export const UPDATE_END_DATE = 'UPDATE_END_DATE';
export const UPDATE_GRAPH_TICK_VALUES = 'UPDATE_GRAPH_TICK_VALUES';
// PROCESS TELEMETRY DATA
export const GET_AVERAGE_FUEL_CONSUMPTION = 'GET_AVERAGE_FUEL_CONSUMPTION';
export const SET_AVERAGE_FUEL_CONSUMPTION = 'SET_AVERAGE_FUEL_CONSUMPTION';
export const RESET_AVERAGE_FUEL_CONSUMPTION = 'RESET_AVERAGE_FUEL_CONSUMPTION';
export const GET_AVERAGE_ENERGY_CONSUMPTION = 'GET_AVERAGE_ENERGY_CONSUMPTION';
export const SET_AVERAGE_ENERGY_CONSUMPTION = 'SET_AVERAGE_ENERGY_CONSUMPTION';
export const RESET_AVERAGE_ENERGY_CONSUMPTION =
  'RESET_AVERAGE_ENERGY_CONSUMPTION';
export const GET_FUEL_CONSUMED = 'GET_FUEL_CONSUMED';
export const SET_FUEL_CONSUMED = 'SET_FUEL_CONSUMED';
export const RESET_FUEL_CONSUMED = 'RESET_FUEL_CONSUMED';
export const GET_TOTAL_HOURS = 'GET_TOTAL_HOURS';
export const SET_TOTAL_HOURS = 'SET_TOTAL_HOURS';
export const RESET_TOTAL_HOURS = 'RESET_TOTAL_HOURS';
// CALCULATE AVERAGES
export const GET_AVERAGE_ENGINE_UTILIZATION = 'GET_AVERAGE_ENGINE_UTILIZATION';
export const SET_AVERAGE_ENGINE_UTILIZATION = 'SET_AVERAGE_ENGINE_UTILIZATION';
export const RESET_AVERAGE_ENGINE_UTILIZATION =
  'RESET_AVERAGE_ENGINE_UTILIZATION';
export const GET_AVERAGE_THROUGHPUTS = 'GET_AVERAGE_THROUGHPUTS';
export const SET_AVERAGE_THROUGHPUTS = 'SET_AVERAGE_THROUGHPUTS';
export const RESET_AVERAGE_THROUGHPUTS = 'RESET_AVERAGE_THROUGHPUTS';
export const GET_AVERAGE_FUEL_LEVELS = 'GET_AVERAGE_FUEL_LEVELS';
export const SET_AVERAGE_FUEL_LEVELS = 'SET_AVERAGE_FUEL_LEVELS';
export const RESET_AVERAGE_FUEL_LEVELS = 'RESET_AVERAGE_FUEL_LEVELS';
export const GET_AVERAGE_ENERGY_UTILIZATION = 'GET_AVERAGE_ENERGY_UTILIZATION';
export const SET_AVERAGE_ENERGY_UTILIZATION = 'SET_AVERAGE_ENERGY_UTILIZATION';
export const RESET_AVERAGE_ENERGY_UTILIZATION =
  'RESET_AVERAGE_ENERGY_UTILIZATION';
