import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  GoogleMapProps,
} from 'react-google-maps';
import { mapStyleDark } from '../../constants';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import RubbleMarker from './RubbleMarker';
import { connect } from 'react-redux';
import { IReduxState, IMachine } from '../../types';

interface IProps {
  hideOverlay: boolean;
  showMarkerCluster: boolean;
  disableClick?: boolean;
  machines: IMachine[];
  center?: {
    lat: number;
    lng: number;
  };
  panToCenter?: {
    lat: number;
    lng: number;
  };
  options?: {
    styles: any;
    streetViewControl: boolean;
    fullscreenControl: boolean;
  };
  zoom?: number;
  filteredMachines?: IMachine[];
  allMachines?: IMachine[];
}

type IMergedProps = GoogleMapProps & IProps;

class Map extends React.Component<IMergedProps> {
  render() {
    const defaultOptions = {
      mapTypeControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite'],
        // @ts-ignore
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        // @ts-ignore
        position: google.maps.ControlPosition.BOTTOM_CENTER,
      },
      clickableIcons: false,
      styles: mapStyleDark,
      streetViewControl: false,
      fullscreenControl: false,
    };
    const defaultZoom = 9;
    const {
      machines,
      showMarkerCluster,
      hideOverlay,
      center,
      options,
      zoom,
      panToCenter,
      filteredMachines,
      allMachines,
      disableClick,
    } = this.props;
    // @ts-ignore
    const bounds = new window.google.maps.LatLngBounds();
    const rubbleMarkers = machines.map((item: IMachine) => {
      if (
        !!item.telemetrySnapshot &&
        !!item.telemetrySnapshot.location?.latitude &&
        !!item.telemetrySnapshot.location.longitude
      ) {
        bounds.extend({
          lat: item.telemetrySnapshot?.location?.latitude,
          lng: item.telemetrySnapshot?.location?.longitude,
        });
        return (
          <RubbleMarker
            filteredMachines={!!filteredMachines ? filteredMachines : null}
            disableClick={disableClick}
            key={`marker-${item.id}`}
            machine={item}
            hideOverlay={hideOverlay}
          />
        );
      } else return null;
    });

    const getMapBounds = (map: any) => {
      if (map && !center) {
        if (
          filteredMachines &&
          filteredMachines.length > 1 &&
          allMachines?.length !== filteredMachines.length
        ) {
          map.fitBounds(bounds);
        }
      }
    };

    return (
      <React.Fragment>
        <GoogleMap
          defaultZoom={zoom ? zoom : defaultZoom}
          center={center ? center : panToCenter}
          defaultOptions={options ? options : defaultOptions}
          ref={(map) => getMapBounds(map)}
        >
          {showMarkerCluster ? (
            <MarkerClusterer
              ignoreHidden={true}
              averageCenter
              minimumClusterSize={2}
              gridSize={20}
            >
              {rubbleMarkers}
            </MarkerClusterer>
          ) : (
            rubbleMarkers
          )}
        </GoogleMap>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IReduxState) => {
  return {
    panToCenter: state.search.map.panToCenter,
    allMachines: state.machines.allMachines,
    filteredMachines: state.machines.filteredMachines,
  };
};

export const RubbleMap = connect(
  mapStateToProps,
  null
)(withScriptjs(withGoogleMap(Map)));
