import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import rmBackground from '../../assets/img/rm_welcome-screen.jpg';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { redirectLogin, fetchUserSession } from '../../actions/authActions';
import ServiceCard from './ServiceCard';
import { useHistory } from 'react-router';
import { openLinkWithRewrite, routes } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vH - 96px)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vH - 96px)',
    },
    background: `url(${rmBackground})`,
    backgroundPosition: 'top',
    backgroundSize: '600px 600px',
    [theme.breakpoints.up('sm')]: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
    },
    position: 'relative',
  },
  container: {
    position: 'relative',
    [theme.breakpoints.up('xl')]: {
      minWidth: '1900px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cardsWrapper: {
    maxWidth: 1680,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  headerWrapper: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    maxWidth: 1680,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      textAlign: 'inherit',
    },
  },
  titleWrapper: {
    margin: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      width: `calc(100VW - ${theme.spacing(16)}px)`,
      margin: 0,
    },
    [theme.breakpoints.up('xl')]: {
      position: 'absolute',
      width: 'auto',
      left: `${theme.spacing(4)}px`,
      top: `-${theme.spacing(4)}px`,
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    letterSpacing: theme.spacing(1),
  },
  title1: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    fontFamily:
      '"EurostileLTStdDemi", "Jura", "Roboto", "Helvetica", "Arial", sans-serif',
    letterSpacing: '4px',
    transform: 'scale(1, 0.9)',
  },
  title2: {
    fontFamily:
      '"EurostileLTStd", "Jura", "Roboto", "Helvetica", "Arial", sans-serif',
    transform: 'scaleX(1.2)',
    WebkitTransform: 'scaleX(1.2)',
    display: 'inline-block',
    marginLeft: '32px',
  },
  subtitleWrapper: {
    textAlign: 'left',
  },
  subtitle: {
    fontFamily: 'EurostileNextLTPro',
    textTransform: 'uppercase',
  },
  firstLineCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  firstLineCardsHelper: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
      width: '225px',
      margin: theme.spacing(4),
    },
  },
}));

export default function Portal() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  React.useEffect(() => {
    dispatch(fetchUserSession());
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerWrapper}>
          <div className={classes.titleWrapper}>
            <div className={classes.title}>
              <Typography
                className={classes.title1}
                variant="h2"
                color="textPrimary"
              >
                {t('login.title1')}
              </Typography>
              <Typography
                className={classes.title2}
                variant="h3"
                color="textPrimary"
              >
                {t('login.title2')}
              </Typography>
            </div>
            <div className={classes.subtitleWrapper}>
              <Typography
                className={classes.subtitle}
                variant="h5"
                color="textPrimary"
              >
                {t('login.subtitle1')} -
              </Typography>
              <Typography
                className={classes.subtitle}
                variant="h5"
                color="textPrimary"
              >
                {t('login.subtitle2')}
              </Typography>
            </div>
          </div>
          <div className={classes.firstLineCards}>
            <div className={classes.firstLineCardsHelper} />
            <ServiceCard
              type="digital"
              cardTitle={t('login.ecoSystem')}
              description={t('login.ecoSystemDescription')}
              isActive={true}
              isAvailable={true}
              onClickHandler={() => history.push(routes.welcome.pathName)}
            />
            <ServiceCard
              type="fleetmanagement"
              cardTitle={t('login.fleetManagement')}
              description={t('login.fleetManagementDescription')}
              loginButtonLabel={t('login.activated')}
              isActive={true}
              isAvailable={true}
              onClickHandler={() =>
                dispatch(redirectLogin(routes.map.pathName))
              }
            />
            <ServiceCard
              type="documentation"
              cardTitle={t('login.documentation')}
              description={t('login.documentationDescription')}
              loginButtonLabel={t('login.activated')}
              isActive={true}
              isAvailable={true}
              onClickHandler={() =>
                openLinkWithRewrite(routes.documentation.pathName)
              }
            />
          </div>
        </div>
        <div className={classes.cardsWrapper}>
          <ServiceCard
            type="cockpit"
            cardTitle={t('login.cockpit')}
            description={t('login.cockpitDescription')}
            loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.cockpit.pathName)}
          />
          <ServiceCard
            type="webshop"
            cardTitle={t('login.webShop')}
            description={t('login.webShopDescription')}
            loginButtonLabel={t('login.activated')}
            isActive={true}
            isAvailable={true}
            onClickHandler={() => openLinkWithRewrite(routes.webShop.pathName)}
          />
          <ServiceCard
            type="machineConfigurator"
            cardTitle={t('login.machineConfigurator')}
            isActive={true}
            isAvailable={true}
            loginButtonLabel={t('login.activated')}
            onClickHandler={() => openLinkWithRewrite(routes.machineConfigurator.pathName)}
          />
          <ServiceCard
            type="investmentCalculator"
            cardTitle={t('login.investmentCalculator')}
            isActive={false}
            isAvailable={false}
            loginButtonLabel={t('login.notActivated')}
          />
        </div>
      </div>
    </div>
  );
}
