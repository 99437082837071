export interface IRoute {
  pathName: string;
  showSearch: boolean;
  searchOverlap: boolean;
}

export interface IRoutes {
  [key: string]: IRoute;
}

export const routes: IRoutes = {
  portal: {
    pathName: '/',
    showSearch: false,
    searchOverlap: true,
  },
  login: {
    pathName: '/login',
    showSearch: false,
    searchOverlap: true,
  },
  welcome: {
    pathName: '/welcome',
    showSearch: false,
    searchOverlap: true,
  },
  fleetManagement: {
    pathName: '/fleetmanagement',
    showSearch: true,
    searchOverlap: true,
  },
  map: {
    pathName: '/fleetmanagement/map',
    showSearch: true,
    searchOverlap: true,
  },
  list: {
    pathName: '/fleetmanagement/list',
    showSearch: true,
    searchOverlap: false,
  },
  about: {
    pathName: '/fleetmanagement/about',
    showSearch: false,
    searchOverlap: true,
  },
  documentation: {
    pathName: '/documentation',
    showSearch: false,
    searchOverlap: true,
  },
  cockpit: {
    pathName: 'https://rmcockpit.azurewebsites.net/', //'/cockpit',
    showSearch: false,
    searchOverlap: true,
  },
  machineConfigurator: {
    pathName: '/machineConfigurator',
    showSearch: false,
    searchOverlap: true,
  },
  webShop: {
    pathName: '/webshop',
    showSearch: false,
    searchOverlap: true,
  },
  notFound: {
    pathName: '*',
    showSearch: false,
    searchOverlap: true,
  },
};
