import React from 'react';
import { chartColors, themeColors } from '../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../types';
import SkeletonLoaderCardSmall from './SkeletonLoaderCardSmall';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      position: 'relative',
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      position: 'relative',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    title: {
      margin: '1rem 0',
      filter: `drop-shadow(1px 2px 3px ${chartColors.electricBlue})`,
    },
    gaugesWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
    noData: {
      height: 100,
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    savingsWrapper: {
      margin: `1rem`,
    },
    saved: {
      margin: `1rem`,
    },
    claim: {
      color: chartColors.electricBlue,
      fontSize: '1rem',
      textAlign: 'center',
      fontStyle: 'italic',
    },
    rightCorner: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 2,
      height: '0.2rem',
      width: '0.2rem',
      backgroundColor: chartColors.electricBlue,
    },
  })
);

export default function MachineEnergySavings() {
  const classes = useStyles();
  const { t } = useTranslation();

  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );

  const { co2Emission, averageFuelLevels } = useSelector(
    (state: IReduxState) => state.machineDetails
  );

  // isJawCrusher
  // default: false
  const isJawCrusher = useSelector(
    (state: IReduxState) => state.machineDetails.machine.machineType.isJawCrusher ?? false
  )  
  
  console.log("***************************************************************************");
  console.log("In MachineEnergySavings");
  console.log("***************************************************************************");
  // console.log("isJawCrusher : " + isJawCrusher);
  // console.log("co2Emission : " + co2Emission?.value);

  return (
    <div className={classes.root}>
      <div
        className={classes.card}
        style={{
          boxShadow: `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`,
        }}
      >
        <div className={classes.rightCorner} />
        <Card
          className={classes.cardContent}
          style={{
            boxShadow: `inset ${chartColors.electricBlue} 0 0 2rem 1rem`,
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
              paddingTop: isFetchingTelemetry ? '1rem' : '2rem',
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageFuelLevels.timestamp
                      ? moment
                          .utc(averageFuelLevels.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h5"
                >
                  {/* {t('graph.energySavings')} */}
                  <span>
                  CO<sub>2</sub>{t('graph.co2Emissions').replace("CO2", "")}
                  </span>
                </Typography>
                <div className={classes.savingsWrapper}>
                <Typography
                    align="center"
                    variant="h5"
                    className={classes.saved}
                  >
                    {co2Emission !== null ? (
                      <span>
                        {`${co2Emission.value}  ${co2Emission.unit}`} CO<sub>2</sub>
                      </span>
                    ) : (
                      t('graph.noData')
                    )}
                  </Typography>
                  {/* <Typography
                    align="center"
                    variant="body1"
                    className={classes.claim}
                  >
                    {t('graph.estimatedEnergyClaim')}
                  </Typography> */}
                </div>
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
