/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorDto,
    ErrorDtoFromJSON,
    ErrorDtoFromJSONTyped,
    ErrorDtoToJSON,
    SampledTelemetryDto,
    SampledTelemetryDtoFromJSON,
    SampledTelemetryDtoFromJSONTyped,
    SampledTelemetryDtoToJSON,
    TelemetryRangeAveragesDto,
    TelemetryRangeAveragesDtoFromJSON,
    TelemetryRangeAveragesDtoFromJSONTyped,
    TelemetryRangeAveragesDtoToJSON,
    TelemetryRangeSavingsDto,
    TelemetryRangeSavingsDtoFromJSON,
    TelemetryRangeSavingsDtoFromJSONTyped,
    TelemetryRangeSavingsDtoToJSON,
    TelemetryRangeTotalsDto,
    TelemetryRangeTotalsDtoFromJSON,
    TelemetryRangeTotalsDtoFromJSONTyped,
    TelemetryRangeTotalsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TelemetryRangeDto
 */
export interface TelemetryRangeDto {
    /**
     * 
     * @type {Array<SampledTelemetryDto>}
     * @memberof TelemetryRangeDto
     */
    telemetry?: Array<SampledTelemetryDto> | null;
    /**
     * 
     * @type {Array<ErrorDto>}
     * @memberof TelemetryRangeDto
     */
    errorCodes?: Array<ErrorDto> | null;
    /**
     * 
     * @type {TelemetryRangeTotalsDto}
     * @memberof TelemetryRangeDto
     */
    totals?: TelemetryRangeTotalsDto | null;
    /**
     * 
     * @type {TelemetryRangeAveragesDto}
     * @memberof TelemetryRangeDto
     */
    averages?: TelemetryRangeAveragesDto | null;
    /**
     * 
     * @type {TelemetryRangeSavingsDto}
     * @memberof TelemetryRangeDto
     */
    savings?: TelemetryRangeSavingsDto | null;
}

export function TelemetryRangeDtoFromJSON(json: any): TelemetryRangeDto {
    return TelemetryRangeDtoFromJSONTyped(json, false);
}

export function TelemetryRangeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryRangeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'telemetry': !exists(json, 'telemetry') ? undefined : (json['telemetry'] === null ? null : (json['telemetry'] as Array<any>).map(SampledTelemetryDtoFromJSON)),
        'errorCodes': !exists(json, 'errorCodes') ? undefined : (json['errorCodes'] === null ? null : (json['errorCodes'] as Array<any>).map(ErrorDtoFromJSON)),
        'totals': !exists(json, 'totals') ? undefined : TelemetryRangeTotalsDtoFromJSON(json['totals']),
        'averages': !exists(json, 'averages') ? undefined : TelemetryRangeAveragesDtoFromJSON(json['averages']),
        'savings': !exists(json, 'savings') ? undefined : TelemetryRangeSavingsDtoFromJSON(json['savings']),
    };
}

export function TelemetryRangeDtoToJSON(value?: TelemetryRangeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'telemetry': value.telemetry === undefined ? undefined : (value.telemetry === null ? null : (value.telemetry as Array<any>).map(SampledTelemetryDtoToJSON)),
        'errorCodes': value.errorCodes === undefined ? undefined : (value.errorCodes === null ? null : (value.errorCodes as Array<any>).map(ErrorDtoToJSON)),
        'totals': TelemetryRangeTotalsDtoToJSON(value.totals),
        'averages': TelemetryRangeAveragesDtoToJSON(value.averages),
        'savings': TelemetryRangeSavingsDtoToJSON(value.savings),
    };
}


