import {
  ADD_MACHINE_TAG_FAIL,
  ADD_MACHINE_TAG_REQUEST,
  ADD_MACHINE_TAG_SUCCESS,
  DELETE_MACHINE_NOTE_FAIL,
  DELETE_MACHINE_NOTE_REQUEST,
  DELETE_MACHINE_NOTE_SUCCESS,
  DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL,
  DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST,
  DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS,
  DELETE_MACHINE_TAG_FAIL,
  DELETE_MACHINE_TAG_REQUEST,
  DELETE_MACHINE_TAG_SUCCESS,
  EDIT_MACHINE_NOTE_FAIL,
  EDIT_MACHINE_NOTE_REQUEST,
  EDIT_MACHINE_NOTE_SUCCESS,
  EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL,
  EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST,
  EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS,
  FETCH_MACHINE_DETAILS_ADDRESS_FAIL,
  FETCH_MACHINE_DETAILS_ADDRESS_REQUEST,
  FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS,
  FETCH_MACHINE_DETAILS_FAIL,
  FETCH_MACHINE_DETAILS_REQUEST,
  FETCH_MACHINE_DETAILS_SUCCESS,
  FETCH_MACHINE_TELEMETRY_FAIL,
  FETCH_MACHINE_TELEMETRY_REQUEST,
  FETCH_MACHINE_TELEMETRY_SUCCESS,
  LAST_TELEMETRY_TIMESTAMP,
  GET_AVERAGE_ENERGY_CONSUMPTION,
  GET_AVERAGE_ENERGY_UTILIZATION,
  GET_AVERAGE_ENGINE_UTILIZATION,
  GET_AVERAGE_FUEL_CONSUMPTION,
  GET_AVERAGE_FUEL_LEVELS,
  GET_AVERAGE_THROUGHPUTS,
  GET_FUEL_CONSUMED,
  RESET_AVERAGE_ENERGY_CONSUMPTION,
  RESET_AVERAGE_ENERGY_UTILIZATION,
  RESET_AVERAGE_ENGINE_UTILIZATION,
  RESET_AVERAGE_FUEL_CONSUMPTION,
  RESET_AVERAGE_FUEL_LEVELS,
  RESET_AVERAGE_THROUGHPUTS,
  RESET_MACHINE_DETAILS,
  RESET_MACHINE_TELEMETRY,
  SET_AVERAGE_ENERGY_CONSUMPTION,
  SET_AVERAGE_ENERGY_UTILIZATION,
  SET_AVERAGE_ENGINE_UTILIZATION,
  SET_AVERAGE_FUEL_CONSUMPTION,
  SET_AVERAGE_FUEL_LEVELS,
  SET_AVERAGE_THROUGHPUTS,
  SET_FUEL_CONSUMED,
  SET_TOTAL_HOURS,
  UPDATE_END_DATE,
  UPDATE_GRAPH_TICK_VALUES,
  UPDATE_MACHINE_STATE_FAIL,
  UPDATE_MACHINE_STATE_REQUEST,
  UPDATE_MACHINE_STATE_SUCCESS,
  UPDATE_MAP_CENTER,
  UPDATE_START_DATE,
} from './types';

import {
  basePath,
  googleApiKey,
  googleGeoCodeUrl,
  machinesApi,
  machineTagsApi,
} from '../constants';
import { IMachine, IReduxState, ISampledTelemetry } from '../types';
import { fetchMachineStateOptions, fetchMachineTags } from './machineActions';
import moment from 'moment';
import {
  MeasurementSystem,
  SampledTelemetryDto,
  TelemetryRangeAveragesDto,
  TelemetryRangeTotalsDto,
} from '../rubblemaster_openapi3_typescript-fetch/models';
// import MachineStats from '../components/MachineDetails/MachineStats';
// import { useSelector } from 'react-redux';

export const fetchMachineDetails = (machineId: number, curEnddate : Date = new Date()) => {
  return (dispatch: any, getState: () => IReduxState) => {
    dispatch({ type: FETCH_MACHINE_DETAILS_REQUEST });
    //const endDate = new Date();
    const endDate = curEnddate;
    dispatch(updateEndDate(endDate));
    const startDate = getState().machineDetails.search.startDate;
    const {
      resolutionInSeconds,
      graphTickValues,
      graphDateFormat,
    } = calculateResolutionAndGraphTicks(startDate, endDate);
    fetch(`${basePath}/api/machines/${machineId}`, { credentials: 'include' })
      .then((res: any) => res.json())
      .then((machine: IMachine) => {
        dispatch(fetchMachineTags());
        dispatch(fetchMachineStateOptions());
        dispatch({
          type: FETCH_MACHINE_DETAILS_SUCCESS,
          payload: machine,
        });
        dispatch({
          type: UPDATE_GRAPH_TICK_VALUES,
          payload: {
            graphTickValues,
            graphDateFormat,
          },
        });
        dispatch(
          fetchTelemetryMachineData(
            machine.id,
            startDate,
            endDate,
            resolutionInSeconds
          )
        );
        if (
          !!machine.telemetrySnapshot &&
          !!machine.telemetrySnapshot.location
        ) {
          const { latitude, longitude } = machine.telemetrySnapshot.location;
          dispatch({
            type: UPDATE_MAP_CENTER,
            payload: {
              lat: latitude,
              lng: longitude,
            },
          });
          dispatch(getSuggestedAddressForMachineDetails(latitude, longitude));
        }
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: FETCH_MACHINE_DETAILS_FAIL });
        window.location.replace(`${basePath}/login`);
      });
  };
};

export const calculateResolutionAndGraphTicks = (
  startDate: Date,
  endDate: Date
) => {
  const secondsDifference = moment(endDate).diff(moment(startDate), 'seconds');
  let resolutionInSeconds: number | null;
  // graphTickValues - More info on axis ticks: https://nivo.rocks/guides/axes
  let graphTickValues = 'every day';
  let graphDateFormat = 'DD.MM';
  switch (secondsDifference !== 0) {
    case secondsDifference <= 86400: // 1 day
      resolutionInSeconds = null; // return raw data
      graphTickValues = 'every hour';
      graphDateFormat = 'HH:mm';
      break;
    case secondsDifference > 86400 && secondsDifference <= 86400 * 7: // 7 days
      resolutionInSeconds = 3600; // seconds per hour
      break;
    case secondsDifference > 86400 && secondsDifference <= 86400 * 14: // 14 days
      resolutionInSeconds = 3600 * 2;
      break;
    case secondsDifference > 86400 && secondsDifference <= 86400 * 30: // 30 days
      resolutionInSeconds = 3600 * 4;
      graphTickValues = 'every 3 day';
      graphDateFormat = 'DD.MM';
      break;
    default:
      resolutionInSeconds = 86400;
      graphTickValues = 'every day';
      graphDateFormat = 'DD.MM';
      break;
  }
  return {
    resolutionInSeconds,
    graphTickValues,
    graphDateFormat,
  };
};

export const updateMachineTelemetryData = (
  startDate: Date,
  endDate: Date,
  machineId: number
) => {

  console.log("***************************************");
  console.log("In updateMachineTelemetryData!! ");
  console.log("***************************************");

  const resolutionInSeconds = calculateResolutionAndGraphTicks(
    startDate,
    endDate
  ).resolutionInSeconds;
  console.log("resolutionInSeconds:");
  console.log(resolutionInSeconds);

  const { graphTickValues, graphDateFormat } = calculateResolutionAndGraphTicks(
    startDate,
    endDate
  );

  // const machine = useSelector(
  //   (state: IReduxState) => state.machineDetails.machine
  // );

  console.log("calculateResolutionAndGraphTicks:");
  console.log({ graphTickValues, graphDateFormat });

  return (dispatch: any) => {
    dispatch({
      type: UPDATE_GRAPH_TICK_VALUES,
      payload: { graphTickValues, graphDateFormat },
    });

    dispatch(
      fetchTelemetryMachineData(
        machineId,
        startDate,
        endDate,
        resolutionInSeconds
      )
    );

    // console.log("machine.telemetrySnapshot");
    // console.log(machine.telemetrySnapshot);

    // // Update lastReceivedValue
    // console.log("Update lastReceivedValue:");
    // dispatch(
    //   MachineStats()
    // );

  };
};

export const fetchTelemetryMachineData = (
  machineId: number,
  from: Date,
  till: Date,
  resolutionInSeconds?: number | null | undefined
) => {
  return async (dispatch: any, getState: () => IReduxState) => {
    try {
      const measurementSystem =
        getState().auth.user?.measurementSystem ?? MeasurementSystem.Metric;
      dispatch({ type: FETCH_MACHINE_TELEMETRY_REQUEST });
      const telemetryDataResponse = await fetch(
        `${basePath}/api/telemetry/${machineId}?from=${from.toISOString()}&till=${till.toISOString()}${
          resolutionInSeconds !== null
            ? `&resolutionInSeconds=${resolutionInSeconds}`
            : ''
        }&measurementSystem=${measurementSystem}`,
        { credentials: 'include' }
      );

      if (telemetryDataResponse.status === 204) {
        throw new Error('No Telemetry Data');
      }

      const telemetryRange = await telemetryDataResponse.json();
      dispatch({
        type: FETCH_MACHINE_TELEMETRY_SUCCESS,
        payload: {
          telemetry: telemetryRange.telemetry,
          // errorCodes truncated to the last 1000 occurrences
          errorCodes: !!telemetryRange.errorCodes
            ? telemetryRange.errorCodes.length > 1000
              ? telemetryRange.errorCodes.slice(0, 1000)
              : telemetryRange.errorCodes
            : telemetryRange.errorCodes,
        },
      });

      const filteredTelemetry = telemetryRange.telemetry.filter(
        (item: ISampledTelemetry) =>
          item !== null
        );

      const maxTimeStamp = filteredTelemetry[filteredTelemetry.length - 1].timestamp ?? null;
      console.log("maxTimeStamp");
      console.log(maxTimeStamp);

      if (!!telemetryRange.telemetry) {
        dispatch(
          setAverages(
            telemetryRange.averages,
            telemetryRange.telemetry,
            telemetryRange.totals
          )
        );

        dispatch({
          type : LAST_TELEMETRY_TIMESTAMP,
          payload : maxTimeStamp,
        });

        dispatch({
          type: SET_FUEL_CONSUMED,
          payload: telemetryRange.totals?.totalEngineFuelConsumed,
        });

        dispatch({
          payload: {
            totalWeightLoad1: telemetryRange.totals?.totalWeightLoad1,
            totalWeightLoad2: telemetryRange.totals?.totalWeightLoad2,
            totalWeightLoad3: telemetryRange.totals?.totalWeightLoad3,
            totalWeightLoad4: telemetryRange.totals?.totalWeightLoad4,
            totalHoursOfEngineOperation:
              telemetryRange.totals?.totalHoursOfEngineOperation,
            totalHoursOfCrusherOperation:
              telemetryRange.totals?.totalHoursOfCrusherOperation,
            totalHoursOfExternalPowerSupply:
              telemetryRange.totals?.totalHoursOfExternalPowerSupply,
            totalHoursOfInternalPowerSupply:
              telemetryRange.totals?.totalHoursOfInternalPowerSupply,
            co2Saved: telemetryRange.savings?.co2Saved,
            dieselSaved: telemetryRange.savings?.dieselSaved,
            energySaved: telemetryRange.savings?.energySaved,
            co2Emission: telemetryRange.savings?.co2Emission,
          },
          type: SET_TOTAL_HOURS,
        });

        if (resolutionInSeconds === null) {
          // raw telemetry data
          dispatch(CalculateFuelConsumed(telemetryRange.telemetry, from, till));
        }

        const isHybrid = !!getState().machineDetails.machine.telemetrySnapshot
          ?.isHybrid;

        if (isHybrid) {
          dispatch(calculateAverageEnergyUtilization(telemetryRange.telemetry));
        }
      }
    } catch (error) {
      // TODO: add error logger
      dispatch({ type: FETCH_MACHINE_TELEMETRY_FAIL });
      // Reset Telemetry data, if previous data is available and time range changed
      dispatch({ type: RESET_MACHINE_TELEMETRY });
    }
  };
};

// Calculate fuel consumed if RAW telemetry data
export const CalculateFuelConsumed = (
  sampledTelemetry: ISampledTelemetry[],
  from: Date,
  till: Date
) => {
  return (dispatch: any) => {
    console.log("In CalculateFuelConsumed")
    if (!!sampledTelemetry) {
      dispatch({ type: GET_FUEL_CONSUMED });
      // KBC: Erweiterung des Filters um '&& item.engineFuelRate?.value !== undefined && item.engineFuelRate?.value > 0'
      const engineFuelArray = sampledTelemetry
        .filter((item: ISampledTelemetry) => item.engineFuelRate !== null && item.engineFuelRate?.value !== undefined && item.engineFuelRate?.value > 0)
        .map((item: ISampledTelemetry) => item.engineFuelRate?.value as number);
      console.log(engineFuelArray);

      // wenn Daten vorhanden:
      if (engineFuelArray.length) {
        // Ermittlung der Summe
        const engineFuelConsumedTotal = engineFuelArray.reduce((prev, curr) => prev + curr);
        console.log("Total: " + engineFuelConsumedTotal);

        // optional START
        // Ermittlung des Durchschnitts
        const engineFuelConsumedAvg =
          engineFuelArray.reduce((prev, curr) => prev + curr) /
          engineFuelArray.length;
        console.log("Average: " + engineFuelConsumedAvg);

        const timespan = moment
          .duration(moment(till).diff(moment(from)))
          .as('hours');

        console.log("Ermittelte timespan: " + timespan);
        const engineFuelConsumedOld = engineFuelConsumedAvg * timespan;
        console.log("Calculated consumptionOld: " + engineFuelConsumedOld);

        const engineFuelConsumed_ = engineFuelConsumedAvg * engineFuelArray.length / 60;
        console.log("Calculated consumption_: " + engineFuelConsumed_);
        // optional END

        const engineFuelConsumed = engineFuelConsumedTotal / 60;
        console.log("Calculated consumptionNew: " + engineFuelConsumed);


        dispatch({
          type: SET_FUEL_CONSUMED,
          payload: {
            value: engineFuelConsumed,
            unit: sampledTelemetry[0].engineFuelRate?.unit,
          },
        });
      }
    }
  };
};

export const setAverages = (
  averages: TelemetryRangeAveragesDto | null | undefined,
  telemetry: Array<SampledTelemetryDto>,
  totals: TelemetryRangeTotalsDto | null | undefined
) => (dispatch: any) => {
  if (averages) {
    // Average Engine Utilization
    if (averages.averageEngineUtilization !== undefined) {
      dispatch({ type: GET_AVERAGE_ENGINE_UTILIZATION });
      const engineUtilizationArray = telemetry.filter(
        (t) => t.percentageLoadAtCurrentSpeed !== null
      );
      dispatch({
        type: SET_AVERAGE_ENGINE_UTILIZATION,
        payload: {
          value: averages.averageEngineUtilization * 100,
          timestamp:
            engineUtilizationArray[engineUtilizationArray.length - 1]
              .timestamp ?? null,
        },
      });
    } else {
      dispatch({ type: RESET_AVERAGE_ENGINE_UTILIZATION });
    }

    // Average Weight Loads or Average Throughput
    if (
      averages.averageWeightLoad1 !== null ||
      averages.averageWeightLoad2 !== null ||
      averages.averageWeightLoad3 !== null ||
      averages.averageWeightLoad4 !== null
    ) {
      dispatch({ type: GET_AVERAGE_THROUGHPUTS });
      const filteredTelemetry = telemetry.filter(
        (item: ISampledTelemetry) =>
          item.weightLoad1 !== null ||
          item.weightLoad2 !== null ||
          item.weightLoad3 !== null ||
          item.weightLoad4 !== null
      );
      dispatch({
        type: SET_AVERAGE_THROUGHPUTS,
        payload: {
          value: {
            weightLoad1: averages.averageWeightLoad1 ?? null,
            weightLoad2: averages.averageWeightLoad2 ?? null,
            weightLoad3: averages.averageWeightLoad3 ?? null,
            weightLoad4: averages.averageWeightLoad4 ?? null,
          },
          timestamp:
            filteredTelemetry[filteredTelemetry.length - 1].timestamp ?? null,
        },
      });
    } else {
      dispatch({ type: RESET_AVERAGE_THROUGHPUTS });
    }

    // Average Fuel Levels
    if (
      averages.averageAdBlueLevel !== null ||
      averages.averageFuelLevel !== null
    ) {
      dispatch({ type: GET_AVERAGE_FUEL_LEVELS });
      const fuelLevelsArr = telemetry.filter(
        (item: ISampledTelemetry) =>
          item.adBlueLevel !== null || item.fuelLevel !== null
      );
      dispatch({
        type: SET_AVERAGE_FUEL_LEVELS,
        payload: {
          value: {
            adBlueLevel: averages.averageAdBlueLevel?.value ?? null,
            fuelLevel: averages.averageFuelLevel?.value ?? null,
          },
          timestamp: fuelLevelsArr[fuelLevelsArr.length - 1].timestamp ?? null,
        },
      });
    } else {
      dispatch({ type: RESET_AVERAGE_FUEL_LEVELS });
    }
  }

  // Average Fuel Consumption
  if (
    averages?.mainDischargeFuelRate !== undefined ||
    averages?.sideDischargeFuelRate !== undefined ||
    averages?.engineFuelRate !== undefined
  ) {
    dispatch({ type: GET_AVERAGE_FUEL_CONSUMPTION });
    const fuelConsumptionArr = telemetry.filter(
      (item: ISampledTelemetry) =>
        item.mainDischargeConveyorFuelRate !== null ||
        item.sideDischargeConveyorFuelRate !== null ||
        item.engineFuelRate !== null
    );
    dispatch({
      type: SET_AVERAGE_FUEL_CONSUMPTION,
      payload: {
        engineFuelRate: averages?.engineFuelRate ?? null,
        mainDischargeConveyorFuelRate: averages?.mainDischargeFuelRate ?? null,
        sideDischargeConveyorFuelRate: averages?.sideDischargeFuelRate ?? null,
        engineFuelConsumed: totals?.totalEngineFuelConsumed,
        timestamp:
          fuelConsumptionArr[fuelConsumptionArr.length - 1].timestamp ?? null,
      },
    });
  } else {
    dispatch({ type: RESET_AVERAGE_FUEL_CONSUMPTION });
  }

  // Average Energy Consumption
  if (
    averages?.energyConsumptionRate !== null ||
    averages.mainDischargeEnergyConsumptionRate !== null
  ) {
    dispatch({ type: GET_AVERAGE_ENERGY_CONSUMPTION });
    const energyConsumptionArr = telemetry.filter(
      (item) =>
        item.energyConsumeRate !== null ||
        item.mainDischargeConveyorEnergyRate !== null ||
        item.sideDischargeConveyorEnergyRate !== null
    );
    dispatch({
      type: SET_AVERAGE_ENERGY_CONSUMPTION,
      payload: {
        energyConsumeRate: averages?.energyConsumptionRate ?? null,
        mainDischargeConveyorEnergyRate:
          averages?.mainDischargeEnergyConsumptionRate ?? null,
        sideDischargeConveyorEnergyRate:
          averages?.sideDischargeEnergyConsumptionRate ?? null,
        totalEnergyConsumed: totals?.totalEnergyConsumed ?? null,
        timestamp: energyConsumptionArr.length
          ? energyConsumptionArr[energyConsumptionArr.length - 1].timestamp
          : null,
      },
    });
  } else {
    dispatch({ type: RESET_AVERAGE_ENERGY_CONSUMPTION });
  }
};

export const calculateAverageEnergyUtilization = (
  sampledTelemetry?: ISampledTelemetry[]
) => {
  return (dispatch: any) => {
    dispatch({ type: GET_AVERAGE_ENERGY_UTILIZATION });
    if (!!sampledTelemetry && sampledTelemetry.length) {
      const energyUtilizationArray = sampledTelemetry.filter(
        (item: ISampledTelemetry) => !!item.crusherDriveCurrentLoad
      );
      if (energyUtilizationArray.length) {
        const sumEngineUtilization = energyUtilizationArray
          .map(
            (item: ISampledTelemetry) => item.crusherDriveCurrentLoad as number
          )
          .reduce((prev, curr) => prev + curr, 0);
        const averageEnergyUtilization =
          sumEngineUtilization / energyUtilizationArray.length;

        dispatch({
          type: SET_AVERAGE_ENERGY_UTILIZATION,
          payload: {
            value: averageEnergyUtilization,
            timestamp:
              energyUtilizationArray[energyUtilizationArray.length - 1]
                .timestamp,
          },
        });
      }
    } else {
      dispatch({ type: RESET_AVERAGE_ENERGY_UTILIZATION });
    }
  };
};

export const updateStartDate = (startDate: Date) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_START_DATE,
      payload: startDate,
    });
  };
};

export const updateEndDate = (endDate: Date) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_END_DATE,
      payload: endDate,
    });
  };
};

export const updateMachineState = (machineId: number, machineState: any) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_MACHINE_STATE_REQUEST });
    machinesApi
      .apiMachinesMachineIdStatePutRaw({ machineId, stateId: machineState.id })
      .then(() => {
        dispatch({
          type: UPDATE_MACHINE_STATE_SUCCESS,
          payload: machineState,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: UPDATE_MACHINE_STATE_FAIL });
      });
  };
};

export const addMachineTag = (machineId: number, machineTag: any) => {
  return (dispatch: any) => {
    dispatch({ type: ADD_MACHINE_TAG_REQUEST });
    machineTagsApi
      .apiMachineTagsPost({ machineId, tagId: machineTag.id })
      .then(() => {
        dispatch({
          type: ADD_MACHINE_TAG_SUCCESS,
          payload: machineTag,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: ADD_MACHINE_TAG_FAIL });
      });
  };
};

export const deleteMachineTag = (machineId: number, machineTag: any) => {
  return (dispatch: any) => {
    dispatch({ type: DELETE_MACHINE_TAG_REQUEST });
    machineTagsApi
      .apiMachineTagsDelete({ machineId, tagId: machineTag.id })
      .then(() => {
        dispatch({
          type: DELETE_MACHINE_TAG_SUCCESS,
          payload: machineTag,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: DELETE_MACHINE_TAG_FAIL });
      });
  };
};

export const editMachineNote = (machineId: number, notes: string) => {
  return (dispatch: any) => {
    dispatch({ type: EDIT_MACHINE_NOTE_REQUEST });
    machinesApi
      .apiMachinesMachineIdNotesPut({
        machineId,
        notes,
      })
      .then(() => {
        dispatch({
          type: EDIT_MACHINE_NOTE_SUCCESS,
          payload: notes,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: EDIT_MACHINE_NOTE_FAIL });
      });
  };
};

export const deleteMachineNote = (machineId: number) => {
  return (dispatch: any) => {
    dispatch({ type: DELETE_MACHINE_NOTE_REQUEST });
    machinesApi
      .apiMachinesMachineIdNotesPut({
        machineId,
        notes: '',
      })
      .then(() => {
        dispatch({ type: DELETE_MACHINE_NOTE_SUCCESS });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: DELETE_MACHINE_NOTE_FAIL });
      });
  };
};

export const editMachineProjectConstructionSite = (
  machineId: number,
  projectConstructionSite: string
) => {
  return (dispatch: any) => {
    dispatch({ type: EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST });
    machinesApi
      .apiMachinesMachineIdProjectConstructionSitePut({
        machineId,
        projectConstructionSite,
      })
      .then(() => {
        dispatch({
          type: EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS,
          payload: projectConstructionSite,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL });
      });
  };
};

export const deleteMachineProjectConstructionSite = (machineId: number) => {
  return (dispatch: any) => {
    dispatch({ type: DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_REQUEST });
    machinesApi
      .apiMachinesMachineIdProjectConstructionSitePut({
        machineId,
        projectConstructionSite: '',
      })
      .then(() => {
        dispatch({ type: DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_FAIL });
      });
  };
};

export const getSuggestedAddressForMachineDetails = (
  latitude: number,
  longitude: number
) => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_MACHINE_DETAILS_ADDRESS_REQUEST });
    fetch(
      `${googleGeoCodeUrl}${latitude},${longitude}&key=${googleApiKey}`
    ).then((res: any) => {
      res.json().then((body: any) => {
        if (body.results[0]) {
          dispatch({
            type: FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS,
            payload: body.results[0].formatted_address,
          });
        } else {
          dispatch({ type: FETCH_MACHINE_DETAILS_ADDRESS_FAIL });
        }
      });
    });
  };
};

export const resetMachineDetails = () => {
  return (dispatch: any) => {
    dispatch({ type: RESET_MACHINE_DETAILS });
  };
};

