import React, { useEffect, useRef } from 'react';
import MachineGeneral from './MachineGeneral';
import MachineMap from './MachineMap';
import MachineStats from './MachineStats';
import { makeStyles, Container, Grid, Theme } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps as IRouteComponentProps } from 'react-router-dom';
import { IReduxState, AccessLevel } from '../../types';
import MachineErrors from './MachineErrors';
import FuelGraphLine from './FuelLevels/FuelGraphLine';
import FuelGraphGauges from './FuelLevels/FuelGraphGauges';
import GraphControls from './GraphControls';
import { fetchMachineDetails } from '../../actions/machineDetailsActions';
import EngineUtilizationLine from './EngineUtilization/EngineUtilizationLine';
import EngineUtilizationGauge from './EngineUtilization/EngineUtilizationGauge';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import FuelConsumptionLine from './FuelConsumption/FuelConsumptionLine';
import FuelConsumptionDisplay from './FuelConsumption/FuelConsumptionDisplay';
import {
  CREATE_PDF_REPORT_SUCCESS,
  CREATE_PDF_REPORT,
  CREATE_PDF_REPORT_FAIL,
} from '../../actions/types';
import ThroughputDisplay from './Throughput/ThroughputDisplay';
import ThroughputLine from './Throughput/ThroughputLine';
import MachineTagsNotes from './MachineTagsNotes';
import { useWindowSize } from '../useWindowSize';
import {
  createPdfReportFooter,
  createPdfReportHeader,
  createGraphReportsPage,
  pdfUnitsFormatter,
} from '../../constants';
import { useIdle } from '../useIdle';
import { handleUserIdle } from '../../actions/authActions';
import { MachineImage } from './MachineImage';
import SkeletonLoaderPdf from './SkeletonLoaderPdf';
import EngineUtilizationGaugeHybrid from './EngineUtilization/EngineUtilizationGaugeHybrid';
import MachineEnergySavings from './MachineEnergySavings';
import FuelConsumptionDisplayHybrid from './FuelConsumption/FuelConsumptionDisplayHybrid';
import { log } from 'console';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: `${theme.spacing(2)}px`,
    flexGrow: 1,
  },
  report: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(4)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px`,
    },
    '@media print': {
      margin: `${theme.spacing(2)}px`,
    },
  },
  graphControls: {
    top: 0,
    width: '100%',
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      marginTop: theme.spacing(4),
    },
  },
}));

export default function MachineDetails(props: IRouteComponentProps<any>) {
  const windowSize = useWindowSize();
  const isMobile = !!windowSize && !!windowSize.width && windowSize.width < 601;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const machineId = props.match.params.id;
  const {
    averageFuelConsumption,
    averageEnergyConsumption,
    suggestedAddress,
    machine,
    search,
    totalHoursOfEngineOperation,
    totalHoursOfCrusherOperation,
    isCreatingPdf,
    totalWeightLoad1,
    totalWeightLoad2,
    totalWeightLoad3,
    totalWeightLoad4,
    energySaved,
    co2Saved,
    dieselSaved,
    co2Emission,
  } = useSelector((state: IReduxState) => state.machineDetails);

  const info = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.info
  );
  
  console.warn("****************************************");
  console.warn("Index");
  console.warn("****************************************");
  console.log("########################################");
  console.warn(info);
  console.log("########################################");
  
  const currentlyAuthenticatedUserAccessLevel: AccessLevel = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.currentlyAuthenticatedUserAccessLevel
  );

  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

  const isThirdParty = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.machineType.isThirdParty
  );

  const isIdle = useIdle();

  // Get all machines on mount
  useEffect(() => {
    dispatch(fetchMachineDetails(machineId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(handleUserIdle(isIdle));
  }, [isIdle, dispatch]);

  // PDF REPORT
  // chart refs are needed for using images in PDF Report
  const fuelGaugesRef = useRef<HTMLDivElement>(null);
  const fuelLevelsRef = useRef<HTMLDivElement>(null);
  const engineUtilizationGaugeRef = useRef<HTMLDivElement>(null);
  const engineUtilizationRef = useRef<HTMLDivElement>(null);
  const fuelConsumptionDisplayRef = useRef<HTMLDivElement>(null);
  const fuelConsumptionLineRef = useRef<HTMLDivElement>(null);
  const throughputDisplayRef = useRef<HTMLDivElement>(null);
  const throughputLineRef = useRef<HTMLDivElement>(null);
  const machineImgRef = useRef<HTMLImageElement>(null);
  // only hybrid machines
  const engineUtilizationGaugeHybridRef = useRef<HTMLDivElement>(null);
  const fuelConsumptionDisplayHybridRef = useRef<HTMLDivElement>(null);

  const renderToCanvas = async (elementToRender: HTMLElement) => {
    return html2canvas(elementToRender, {
      imageTimeout: 0,
      scale: 2,
      ignoreElements: (otherElement) =>
        !otherElement.contains(elementToRender) &&
        !elementToRender?.contains(otherElement) &&
        otherElement.localName !== 'head' &&
        otherElement.localName !== 'style',
    });
  };

  // TODO from KANM: Needs refactoring
  const createReport = async () => {
    dispatch({ type: CREATE_PDF_REPORT });

    console.log("***************************************");
    console.log("In createReport!! ");
    console.log("***************************************");
    console.log("currentlyAuthenticatedUserAccessLevel : " + currentlyAuthenticatedUserAccessLevel);

    try {
      const doc = new jsPDF('landscape');
      const reportTitle = `${t('report.machineReport')}`;
      const reportTimeframe = `${moment(search.startDate).format(
        'DD.MM.YYYY HH:mm'
      )} ${t('report.till')} ${moment(search.endDate).format(
        'DD.MM.YYYY HH:mm'
      )}`;

      const reportFileName = `${t('report.report')} - ${moment(
        search.startDate
      ).format('DD.MM.YYYY HHmm')} ${t('report.till')} ${moment(
        search.endDate
      ).format('DD.MM.YYYY HHmm')}`;

      const serialNumber = !!machine.serialNumber ? machine.serialNumber : '';
      const machineState = !!machine.state && !!machine.state.key ? machine.state.key : '-';

      const workingHoursTillService =
        !!machine.nextService && !!machine.nextService.workingHoursTillService
          ? `${machine.nextService.workingHoursTillService} ${t(
            'machine.stats.hours'
          )}`
          : t('graph.noData');

      const engineOperationTotal =
        totalHoursOfEngineOperation !== null
          ? `${totalHoursOfEngineOperation} ${t('machine.stats.hours')}`
          : t('graph.noData');

      const crusherOperationTotal =
        totalHoursOfCrusherOperation !== null
          ? `${totalHoursOfCrusherOperation} ${t('machine.stats.hours')}`
          : t('graph.noData');
            
      // const energySavingsTxt : string = t('graph.energySavings');
      // const co2EmissionsTxt : string = t('co2Emissions');
      // const co2SavingsTxt : string = t('graph.co2Savings');

      const pdfTotalWeightLoad1 = pdfUnitsFormatter(totalWeightLoad1, t('machine.stats.t'), t('graph.noData'));
      const pdfTotalWeightLoad2 = pdfUnitsFormatter(totalWeightLoad2, t('machine.stats.t'), t('graph.noData'));
      const pdfTotalWeightLoad3 = pdfUnitsFormatter(totalWeightLoad3, t('machine.stats.t'), t('graph.noData'));
      const pdfTotalWeightLoad4 = pdfUnitsFormatter(totalWeightLoad4, t('machine.stats.t'), t('graph.noData'));

      const engineFuelConsumed = pdfUnitsFormatter(averageFuelConsumption.engineFuelConsumed, t('report.liter'), t('graph.noData'));

      const projectConstructionSiteWithAuth =
        currentlyAuthenticatedUserAccessLevel === AccessLevel.Read ||
          currentlyAuthenticatedUserAccessLevel === AccessLevel.Write
          ? !!machine.projectConstructionSite ? machine.projectConstructionSite : t('accessLevel.placeholder')
          : t('accessLevel.placeholder');

      console.log(".. createPdfReportHeader");
      createPdfReportHeader(doc, reportTitle, reportTimeframe);

      const machineImg = { width: 800, height: 450 }; // Original width
      const machineImage = new Image();

      const getMachineImage = (callback: {
        (machineImage: HTMLImageElement): void;
        (arg0: HTMLImageElement): void;
      }) => {
        machineImage.crossOrigin = 'Anonymous';
        machineImage.onerror = () => {
          console.log(`Error loading image for ${machine.machineType.name}`);
        };

        machineImage.onload = () => {
          callback(machineImage);
        };
        machineImage.src = MachineImage(machine.machineType, isHybrid);
        addMachineImage(machineImage);
      };

      const addMachineImage = (machineImage: HTMLImageElement) => {
        if (machineImage) {
          doc.addImage({
            imageData: machineImage,
            x: doc.internal.pageSize.getWidth() - (machineImg.width / 6 + 16),
            y:
              doc.internal.pageSize.getHeight() / 2 -
              machineImg.height / (6 * 2),
            w: machineImg.width / 6,
            h: machineImg.height / 6,
          });
        }
      };

      console.log(".. getMachineImage");
      getMachineImage(addMachineImage);

      const lineHeight = isHybrid ? 7 : 8;
      const lineFormatter = (multiplier: number) => {
        const startY = 40;

        return startY + lineHeight * multiplier;
      };

      let curLine: number = 0;

      // **************************************************
      // serialNumber, owner, machineState
      doc
        .setFontSize(12)
        .setFontType('bold')
        .text(`${t('machine.serialNumber')}:`, 16, lineFormatter(curLine + 1))
        .text(`${t('machine.owner')}:`, 16, lineFormatter(curLine + 2))
        .text(`${t('machine.machineState')}:`, 16, lineFormatter(curLine + 3));

      doc
        .setFontType('normal')
        .text(serialNumber, 80, lineFormatter(curLine + 1))
        .text(machine.owner, 80, lineFormatter(curLine + 2))
        .text(machineState, 80, lineFormatter(curLine + 3));

      curLine += 3;

      // **************************************************
      // crusherOperation (Betriebsstunden), nextService, mainDischargeConveyor (Hauptaustrageband) + 2/3/4

      if (!isThirdParty) {
        doc
          .setFontType('bold')
          .text(`${t('report.crusherOperation')}:`, 16, lineFormatter(curLine + 1))
          .setFontType('normal')
          .text(crusherOperationTotal, 80, lineFormatter(curLine + 1))
        
        curLine ++;
      }

      doc
        .setFontType('bold')
        .text(`${t('report.engineOperation')}:`, 16, lineFormatter(curLine + 1))
        .text(`${t('report.engineFuelConsumed')}:`, 16, lineFormatter(curLine + 2))
        .text(`${t('machine.constructionSite')}:`, 16, lineFormatter(curLine + 3))
        .setFontType('normal')
        .text(engineOperationTotal, 80, lineFormatter(curLine + 1))
        .text(engineFuelConsumed, 80, lineFormatter(curLine + 2))
        .text(projectConstructionSiteWithAuth, 80, lineFormatter(curLine + 3))
        ;
      curLine += 3;

      if (!isThirdParty) {
        doc
          .setFontType('bold')
          .text(`${t('machine.nextService')}:`, 16, lineFormatter(curLine + 1))
          .text(`${t('report.mainDischargeConveyor')}:`, 16, lineFormatter(curLine + 2))
          .setFontType('normal')
          .text(workingHoursTillService, 80, lineFormatter(curLine + 1))
          .text(pdfTotalWeightLoad1, 80, lineFormatter(curLine + 2));
          curLine += 2;

        if (machine.machineType.maxBeltScales && machine.machineType.maxBeltScales >= 2){
          doc
            .setFontType('bold')
            .text(`${t('report.secondDischargeConveyor')}:`, 16, lineFormatter(curLine + 1))
            .setFontType('normal')
            .text(pdfTotalWeightLoad2, 80, lineFormatter(curLine + 1));
          curLine ++;
        }

        if (machine.machineType.maxBeltScales && machine.machineType.maxBeltScales >= 3){
          doc
            .setFontType('bold')
            .text(`${t('report.thirdDischargeConveyor')}:`, 16, lineFormatter(curLine + 1))
            .setFontType('normal')
            .text(pdfTotalWeightLoad3, 80, lineFormatter(curLine + 1));
          curLine ++;
        }
        if (machine.machineType.maxBeltScales && machine.machineType.maxBeltScales >= 4){
          doc
            .setFontType('bold')
            .text(`${t('report.fourthDischargeConveyor')}:`, 16, lineFormatter(curLine + 1))
            .setFontType('normal')
            .text(pdfTotalWeightLoad4, 80, lineFormatter(curLine + 1));
          curLine ++;
        }
      } 

      // **************************************************
      // co2Emissions, Adresse
      if (isHybrid) {
        doc
          .setFontSize(12)
          .setFontType('bold')
          .text(`${t('graph.electricDrive')}:`, 16, lineFormatter(curLine + 1))
          .text(`${t('graph.dieselEngine')}:`, 16, lineFormatter(curLine + 2))
          .text(`${t('graph.energySavings')}:`, 16, lineFormatter(curLine + 3))
          .text(`${t('graph.co2Emissions')}:`, 16, lineFormatter(curLine + 4))
          .setFontType('normal')
          .text(pdfUnitsFormatter(energySaved, t('graph.kwh'), t('graph.noData')), 80, lineFormatter(curLine + 1))
          .text(pdfUnitsFormatter(co2Saved, t('graph.t') + 'CO', t('graph.noData')), 80, lineFormatter(curLine + 2))
          .text(pdfUnitsFormatter(dieselSaved, t('graph.l'), t('graph.noData')), 80, lineFormatter(curLine + 3))
          .text(pdfUnitsFormatter(co2Emission, t('graph.t') + 'CO2', t('graph.noData')), 80, lineFormatter(curLine + 4))
          ;

        curLine += 4;
      }

      // add suggested address to report
      doc
        .setFontSize(12)
        .setFontType('bold')
        .text(`${t('machine.suggestedAddress')}:`, 16, lineFormatter(curLine + 1))
        .setFontType('normal')
        ;

      // Adresse mehrzeilig anzeigen
      const suggestedAddressArray = suggestedAddress.split(', ');
      suggestedAddressArray.forEach((element) => {
        doc
          .text(element, 80, lineFormatter(curLine + 1));
        curLine++;
      });

      // **************************************************

      createPdfReportFooter(doc, serialNumber, t('report.page'), 1, 5);
      doc.addPage();

      // Get all chart images
      const images = await Promise.all([
        renderToCanvas(fuelGaugesRef.current as HTMLElement),
        renderToCanvas(fuelLevelsRef.current as HTMLElement),
        renderToCanvas(
          isHybrid
            ? (engineUtilizationGaugeHybridRef.current as HTMLElement)
            : (engineUtilizationGaugeRef.current as HTMLElement)
        ),
        renderToCanvas(engineUtilizationRef.current as HTMLElement),
        renderToCanvas(
          isHybrid
            ? (fuelConsumptionDisplayHybridRef.current as HTMLElement)
            : (fuelConsumptionDisplayRef.current as HTMLElement)
        ),
        renderToCanvas(fuelConsumptionLineRef.current as HTMLElement),
        renderToCanvas(throughputDisplayRef.current as HTMLElement),
        renderToCanvas(throughputLineRef.current as HTMLElement),
      ]);

      console.log(".. In 9");

      // Throughput
      if (!isThirdParty) {
        createPdfReportHeader(doc, reportTitle, reportTimeframe);
        createGraphReportsPage(doc, images[6], images[7], isMobile);
        createPdfReportFooter(doc, serialNumber, t('report.page'), 2, 5);
        doc.addPage();
      }

      console.log(".. In 10");

      // Fuel Consumption
      createPdfReportHeader(doc, reportTitle, reportTimeframe);
      createGraphReportsPage(doc, images[4], images[5], isMobile);
      createPdfReportFooter(doc, serialNumber, t('report.page'), 3, 5);
      doc.addPage();

      console.log(".. In 11");
      
      // Engine Utilization Graphs
      createPdfReportHeader(doc, reportTitle, reportTimeframe);
      createGraphReportsPage(doc, images[2], images[3], isMobile);
      createPdfReportFooter(doc, serialNumber, t('report.page'), 4, 5);
      doc.addPage();

      console.log(".. In 12");

      // Fuel Levels
      createPdfReportHeader(doc, reportTitle, reportTimeframe);
      createGraphReportsPage(doc, images[0], images[1], isMobile);
      createPdfReportFooter(doc, serialNumber, t('report.page'), 5, 5);
      doc.save(`${reportFileName}.pdf`);

      console.log(".. => complete");

    } 
    catch (err) {
      console.error("########################################################");
      console.error("In createReport => ERROR!! ");
      console.error(err);
      console.error("########################################################");

      dispatch({ type: CREATE_PDF_REPORT_FAIL });
    }

    console.log("***************************************");
    console.log("Ende createReport!! ");
    console.log("***************************************");

    dispatch({ type: CREATE_PDF_REPORT_SUCCESS });
  };

  return (
    <Container maxWidth="xl">
      <div className={classes.root}>
        {isCreatingPdf ? <SkeletonLoaderPdf /> : null}
        {machine ? (
          <Grid container>
            <div className={classes.graphControls}>
              <GraphControls onCreateReport={createReport} />
            </div>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MachineGeneral />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MachineStats />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MachineTagsNotes />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <MachineMap />
            </Grid>
            {/* Display Charts */}
            {!isThirdParty && (
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ThroughputDisplay />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={6} lg={3}>
              {isHybrid ? (
                <EngineUtilizationGaugeHybrid />
              ) : (
                <EngineUtilizationGauge />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              {isHybrid ? (
                <FuelConsumptionDisplayHybrid />
              ) : (
                <FuelConsumptionDisplay />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              {isHybrid ? <MachineEnergySavings /> : <FuelGraphGauges />}
            </Grid>
            {/* Line Charts */}
            {!isThirdParty && (
              <Grid item xs={12}>
                <ThroughputLine />
              </Grid>
            )}
            <Grid item xs={12}>
              <EngineUtilizationLine />
            </Grid>
            <Grid item xs={12}>
              <FuelConsumptionLine />
            </Grid>
            <Grid item xs={12}>
              <FuelGraphLine />
            </Grid>

            {/* TODO: Errors are not yet handled in third party machines */}
            {!isThirdParty && (
              <Grid item xs={12}>
                <MachineErrors startRowsPerPage={5} />
              </Grid>
            )}

            {/* For PDF Report only */}
            <div
              style={{
                display: 'flex',
                position: 'absolute',
                top: -100000,
              }}
            >
              <img
                ref={machineImgRef}
                alt={machine.machineType.name}
                src={MachineImage(machine.machineType, isHybrid)}
              />

              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={fuelGaugesRef}
              >
                <FuelGraphGauges />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 800,
                  height: 550,
                }}
                ref={fuelLevelsRef}
              >
                <FuelGraphLine isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={engineUtilizationGaugeRef}
              >
                <EngineUtilizationGauge />
              </div>

              {/* Only used for hybrid machines */}
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={engineUtilizationGaugeHybridRef}
              >
                <EngineUtilizationGaugeHybrid isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={fuelConsumptionDisplayHybridRef}
              >
                <FuelConsumptionDisplayHybrid isPdfReport />
              </div>

              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 800,
                  height: 550,
                }}
                ref={engineUtilizationRef}
              >
                <EngineUtilizationLine isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={fuelConsumptionDisplayRef}
              >
                <FuelConsumptionDisplay isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 800,
                  height: 550,
                }}
                ref={fuelConsumptionLineRef}
              >
                <FuelConsumptionLine isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 300,
                  height: 550,
                }}
                ref={throughputDisplayRef}
              >
                <ThroughputDisplay isPdfReport />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  // top: -100000,
                  width: 800,
                  height: 550,
                }}
                ref={throughputLineRef}
              >
                <ThroughputLine isPdfReport />
              </div>
            </div>
          </Grid>
        ) : null}
      </div>
    </Container>
  );
}
