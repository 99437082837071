import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RubbleMap } from '../RubbleMap';
import { chartColors, mapStyleDark } from '../../constants';
import ShareMachine from '../ShareMachine';
import NavigateToLocation from './NavigateToLocation';
import { IReduxState } from '../../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { googleMapUrlWithKey } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: 160,
  },
  cardWrapper: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(4)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px`,
      height: 400,
    },
  },
  card: {
    height: '100%',
  },
  actionsArena: {
    padding: 0,
    height: '100%',
  },
  content: {
    height: 'calc(100% - 160px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  share: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    margin: `0 ${theme.spacing(2)}px`,
  },
  noData: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function MachineMap() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { machine, suggestedAddress } = useSelector(
    (state: IReduxState) => state.machineDetails
  );
  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

  const loadingElement = (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );

  const mapOptions = {
    styles: mapStyleDark,
    streetViewControl: false,
    fullscreenControl: false,
    zoomControl: false,
    mapTypeControl: false,
    gestureHandling: 'none',
    draggableCursor: 'default',
    draggingCursor: 'none',
  };

  const mapCenter = machine.telemetrySnapshot?.location
    ? {
        lat: machine.telemetrySnapshot?.location?.latitude,
        lng: machine.telemetrySnapshot?.location?.longitude,
      }
    : null;

  return (
    <div className={classes.root}>
      <div className={classes.cardWrapper}>
        <Card
          className={classes.card}
          style={{
            boxShadow: isHybrid
              ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
              : 'none',
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{ paddingBottom: 0 }}
          >
            {machine.telemetrySnapshot !== null ? (
              <>
                <RubbleMap
                  disableClick
                  center={mapCenter}
                  hideOverlay={true}
                  zoom={6}
                  showMarkerCluster={false}
                  options={mapOptions}
                  machines={new Array(machine)}
                  googleMapURL={googleMapUrlWithKey}
                  loadingElement={loadingElement}
                  containerElement={<div />}
                  mapElement={<div className={classes.mapContainer} />}
                />

                <div className={classes.content}>
                  <List>
                    <ListItem dense alignItems="flex-start">
                      <ListItemText
                        primary={t('machine.suggestedAddress')}
                        secondary={
                          <React.Fragment>
                            <Typography
                              variant="body1"
                              color="textSecondary"
                              component="span"
                            >
                              {suggestedAddress === 'notFound'
                                ? t('machine.notFound')
                                : suggestedAddress}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </List>
                  <div className={classes.share}>
                    <ShareMachine machine={machine} />
                    <NavigateToLocation machine={machine} />
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.noData}>
                <Typography>{t('graph.noData')}</Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
