import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { themeColors, chartColors } from '../../constants';
import { IReduxState, AccessLevel } from '../../types';
import { useTranslation } from 'react-i18next';
import EditMachineState from './EditMachineState';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    flexGrow: 1,
  },
  cardWrapper: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(2)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px`,
      height: 400,
    },
  },
  card: {
    height: '100%',
  },
  actionsArena: {
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    height: '100%',
  },
  chipRed: {
    backgroundColor: themeColors.red,
    color: themeColors.white,
  },
  chipGreen: {
    backgroundColor: themeColors.green,
    color: themeColors.white,
  },
  list: {
    width: '100%',
  },
  listItem: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  machineState: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  lastUpdate: {
    color: themeColors.white,
    marginTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(0),
  },
}));

export default function MachineStats() {
  const classes = useStyles();

  console.log("***************************************");
  console.log("In MachineStats()!! ");
  console.log("***************************************");

  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );

  console.log("machine:");
  console.log(machine);

  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );
  const { t, i18n } = useTranslation();
  const currentlyAuthenticatedUserAccessLevel = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.currentlyAuthenticatedUserAccessLevel
  );

  console.log("currentlyAuthenticatedUserAccessLevel : " + currentlyAuthenticatedUserAccessLevel);

  // const {
  //   totalHoursOfExternalPowerSupply,
  //   totalHoursOfInternalPowerSupply,
  // } = useSelector((state: IReduxState) => state.machineDetails);

  const updatedAt : Date = (useSelector((state: IReduxState) => state.machineDetails.machine.telemetrySnapshot?.updatedAt)) ?? new Date();

  const { averageEngineUtilization, averageFuelLevels } = useSelector(
    (state: IReduxState) => state.machineDetails
  );

  console.log("updatedAt");
  console.log(
    !!machine.telemetrySnapshot
    ? moment
      .utc(updatedAt)
      .local()
      .format(`DD.MM.YYYY HH:mm`)
    : '-'
  );
  console.log(averageEngineUtilization);
  console.log(averageFuelLevels);

    const isThirdParty = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.machineType.isThirdParty
  );

  // returns true if service is closer than 50 hours
  const workingHoursTillService = !!machine.nextService
    ? machine.nextService.workingHoursTillService
    : null;
  const isServiceClose = !!workingHoursTillService
    ? workingHoursTillService < 50.0
    : false;
  
  return (
    <div className={classes.root}>
      <div className={classes.cardWrapper}>
        <Card
          className={classes.card}
          style={{
            boxShadow: isHybrid
              ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
              : 'none',
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
            }}
          >
            <List className={classes.list}>
              <ListItem
                dense
                alignItems="flex-start"
                className={classes.lastUpdate}
                style={isHybrid ? { marginBottom: 0 } : { marginBottom: '8px' }}
              >
                <Typography variant="caption">
                  {`${t('machine.lastReceivedValue')}: ${
                    !!machine.telemetrySnapshot
                      ? moment
                          .utc(updatedAt)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
              </ListItem>
              <ListItem dense alignItems="flex-start">
                <div className={classes.machineState}>
                  <ListItemText
                    className={classes.listItem}
                    primary={t('machine.machineState')}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="span"
                        >
                          {!!machine.state && !!machine.state.key
                            ? i18n.exists(`machine.state.${machine.state.key}`)
                              ? t(`machine.state.${machine.state.key}`)
                              : machine.state.key
                            : '-'}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {
                    currentlyAuthenticatedUserAccessLevel === AccessLevel.Write 
                    ? ( <EditMachineState /> ) 
                    : null}
                </div>
              </ListItem>
              <ListItem
                className={classes.listItem}
                dense
                alignItems="flex-start"
              >
                <ListItemText primary={t('machine.ignition')} />
                <Chip
                  size="small"
                  label={machine.telemetrySnapshot?.isIgnitionOn ? 'on' : 'off'}
                  className={
                    machine.telemetrySnapshot?.isIgnitionOn
                      ? classes.chipGreen
                      : classes.chipRed
                  }
                />
              </ListItem>
              <div style={{ display: 'flex' }}>
                {!isThirdParty && (
                  <ListItem
                    className={classes.listItem}
                    dense
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primary={t('machine.stats.crusherOperationTotal')}
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                          >
                            {!!machine.telemetrySnapshot &&
                            machine.telemetrySnapshot
                              .totalHoursOfCrusherOperation !== null
                              ? machine.telemetrySnapshot
                                  .totalHoursOfCrusherOperation
                              : '-'}
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                )}
                {false && isHybrid && (
                  <ListItem
                    className={classes.listItem}
                    dense
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primary={t('machine.stats.totalInternalHours')}
                      // secondary={
                      //   <React.Fragment>
                      //     <Typography
                      //       variant="body2"
                      //       color="textSecondary"
                      //       component="span"
                      //     >
                      //       {/* {totalHoursOfInternalPowerSupply !== null
                      //         ? totalHoursOfInternalPowerSupply
                      //         : '-'} */}
                      //       {!!machine.telemetrySnapshot &&
                      //       machine.telemetrySnapshot
                      //         .totalHoursOfInternalPowerSupply !== null
                      //         ? machine.telemetrySnapshot
                      //             .totalHoursOfInternalPowerSupply
                      //         : '-'}
                      //     </Typography>
                      //   </React.Fragment>
                      // }
                    />
                  </ListItem>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <ListItem
                  className={classes.listItem}
                  dense
                  alignItems="flex-start"
                >
                  <ListItemText
                    primary={
                      isHybrid
                        ? t('machine.stats.totalDieselHours')
                        : t('machine.stats.engineOperationTotal')
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="span"
                        >
                          {!!machine.telemetrySnapshot &&
                          machine.telemetrySnapshot
                            .totalHoursOfEngineOperation !== null
                            ? machine.telemetrySnapshot
                                .totalHoursOfEngineOperation
                            : '-'}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                {/* momentan nicht verwenden : */}
                {false && isHybrid && (
                  <ListItem
                    className={classes.listItem}
                    dense
                    alignItems="flex-start"
                  >
                    <ListItemText
                      primary={t('machine.stats.totalExternalHours')}
                      // secondary={
                      //   <React.Fragment>
                      //     <Typography
                      //       variant="body2"
                      //       color="textSecondary"
                      //       component="span"
                      //     >
                      //       {/* {totalHoursOfExternalPowerSupply !== null
                      //         ? totalHoursOfExternalPowerSupply
                      //         : '-'} */}
                      //       {!!machine.telemetrySnapshot &&
                      //       machine.telemetrySnapshot
                      //         .totalHoursOfExternalPowerSupply !== null
                      //         ? machine.telemetrySnapshot
                      //             .totalHoursOfInternalPowerSupply
                      //         : '-'}

                      //     </Typography>
                      //   </React.Fragment>
                      // }
                    />
                  </ListItem>
                )}
              </div>
              <ListItem
                className={classes.listItem}
                dense
                alignItems="flex-start"
              >
                <ListItemText
                  disableTypography
                  primary={`${t('graph.fuelLevels')}`}
                  secondary={
                    <div
                      style={{
                        display: isHybrid ? 'flex' : 'block',
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ color: `${chartColors.deepPurple}` }}
                      >
                        {!!machine.telemetrySnapshot &&
                        machine.telemetrySnapshot.fuelLevel !== null
                          ? `${machine.telemetrySnapshot.fuelLevel} % ${t(
                              'graph.diesel'
                            )}`
                          : '-'}
                      </Typography>
                      {isHybrid && (
                        <Typography
                          variant="body1"
                          style={{ margin: '0 0.5rem' }}
                        >
                          /
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        style={{ color: `${chartColors.blue}` }}
                      >
                        {!!machine.telemetrySnapshot &&
                        machine.telemetrySnapshot.adBlueLevel !== null
                          ? `${machine.telemetrySnapshot.adBlueLevel} % ${t(
                              'graph.adblue'
                            )}`
                          : '-'}
                      </Typography>
                    </div>
                  }
                />
              </ListItem>
              {!isThirdParty && (
                <ListItem
                  className={classes.listItem}
                  dense
                  alignItems="flex-start"
                >
                  <ListItemText
                    primary={`${t('machine.nextServiceHours')}`}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          color={isServiceClose ? 'error' : 'textSecondary'}
                          component="span"
                        >
                          {!!machine.nextService
                            ? machine.nextService?.workingHoursTillService
                            : '-'}
                        </Typography>
                        <Typography
                          variant="body1"
                          color={isServiceClose ? 'error' : 'textSecondary'}
                          component="span"
                        >
                          {!!machine.nextService
                            ? ` (${machine.nextService!.name})`
                            : '-'}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              )}
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
